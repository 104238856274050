.discordleaderboard {
    .bingobot {
        .title {
            color: var(--accent-color1);
        }
        .container {
            margin-top: 0;
            .image-container {
                iframe,
                widgetbot {
                    width: 100%;
                    height: 90vh;
                }
                .ellipse-container {
                    .pink {
                        top: 0px;
                        right: 64%;
                        background-color: var(--pink);
                    }
                    .purple {
                        top: 0px;
                        left: 64%;
                        background-color: var(--purple);
                    }
                    .green {
                        background-color: var(--green);
                        right: 50%;
                    }
                    .yellow {
                        background-color: var(--yellow);
                        left: 50%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 280px) and (max-width: 1080px){
    .discordleaderboard {
        .bingobot .container {
            grid-template-columns: 1fr;
            .image-container {
                display: none;
            }
        }
    }
}