.card {
  border-radius: 1rem;
  border: solid 6px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, var(--pink), var(--green));
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition: 0.5s ease-in-out;
  width: 27rem;
  height: auto;
  .card-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    img {
      border-radius: 1rem;
      width: 100%;
      height: auto;
    }
  }
}
