@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --orange: #FF6C11;
  --pink: #FF2079;
  --green: #43FFB5;
  --red: #ff3939;
  --cyan: #2AFFD5;
  --purple: #AA00FF;
  --yellow: #F5FF1E;
  --light-green: #edfff6;
  --light-orange: #fff0e8;
  --accent-color1: #ffffff;
  --accent-color2: #555555;
  --accent-color3: #cccccc;
  --card-color: #000000;
  --background: #212529;
  --font-family: "Inter", sans-serif;
  --background-orange: #FF6C11;
  --background-green: radial-gradient(circle, rgba(67,255,181,1) 0%, rgba(53,221,155,1) 61%, rgba(14,70,48,1) 100%);
  --background-purple: radial-gradient(circle, rgba(170,0,255,1) 0%, rgba(183,40,255,1) 61%, rgba(35,8,48,1) 100%);
}

[data-theme="light"] {
  --background: #ffffff;
  --accent-color1: #000000;
  --accent-color2: #eeeeee;
  --accent-color3: #454545;
  --card-color: #eeeeee;
  --background-orange: rgba(255, 108, 17, 0.5);
  --background-green: radial-gradient(circle, rgba(67,255,181,1) 0%, rgba(186,232,214,1) 45%);
  --background-purple: radial-gradient(circle, rgba(170,0,255,1) 0%, rgba(233,189,255,1) 45%);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.app-container {
  background-color: var(--background);
  font-family: var(--font-family);
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
  padding: 2rem 4rem;
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    padding: 0;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 1080px) {
  .app-container::-webkit-scrollbar {
    display: none;
    position: relative;
  }
}

::-webkit-scrollbar {
  background-color: #212529;
  width: 0.2rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--orange);
}
