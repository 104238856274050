.mynfts {
    .mynfts-content {
        border-top: 0.1rem solid white;
        border-bottom: 0.1rem solid white;
        padding-top: 4rem;
        padding-bottom: 4rem;
        overflow-y: hidden;
        .notifications {
            height: calc(100vh - 200px);
            p {
                color: var(--accent-color1);
            }
        }
        .title {
            color: var(--accent-color1);
        }
        .content {
            margin-top: 0; 
            .description {
                color: white
            }
            .item {
                h4 {
                    padding-top: 1rem;
                    color: var(--accent-color1);
                }
                p {
                    color: var(--accent-color1);
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media screen and (min-width: 280px) and (max-width: 1080px){
    .mynfts {
        .mynfts-content .container {
            grid-template-columns: 1fr;
            .image-container {
                display: none;
            }
        }
    }
}