.team {
  height: 35rem;
  .title {
    color: var(--accent-color1);
  }
  .team-member {
    width: 100%;
    height: 27rem;
    perspective: 1000px;
    .team-member-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 14px;
      color: var(--accent-color1);
      transition: transform 0.6s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      .team-member-front,
      .team-member-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      .team-member-back {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        border: solid 6px transparent;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, var(--pink), var(--green));
        background-origin: border-box;
        background-clip: content-box, border-box;
        transform: rotateY(180deg);
        padding: 1rem;
        .title,
        .subtitle {
          color: var(--pink);
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
        .social-links {
          svg {
            font-size: 24px;
            color: var(--pink);
            margin: 0.5rem;
          }
        }
      }
    }
  }
  .team-member:hover .team-member-inner {
    transform: rotateY(180deg);
    cursor: pointer;
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .team {
    padding: 1rem;
    height: initial;
    .team-member {
      .card {
        width: initial;
        height: initial;
      }
    }
  }
}
