.home {
  background-image: url("../../assets/img/new_images/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: calc(90vh);
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .image-container {
      text-align: center;
      position: relative;
      z-index: 10;
      .ellipse-container {
        .ellipse {
          z-index: -1;
          position: absolute;
          height: 15rem;
          width: 15rem;
          filter: blur(100px);
        }
        .pink {
          top: 40%;
          right: 40%;
          background-color: var(--pink);
        }
        .orange {
          bottom: 40%;
          left: 40%;
          background-color: var(--orange);
        }
      }
    }
    .image-container2 {
      text-align: center;
      position: relative;
      z-index: 10;
      .ellipse-container {
        .ellipse {
          z-index: -1;
          position: absolute;
          height: 15rem;
          width: 15rem;
          filter: blur(100px);
        }
        .purple {
          bottom: -90vh;
          left: -100%;
          background-color: var(--purple);
        }
        .green {
          top: 30vh;
          right: 0%;
          background-color: var(--green);
        }
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .home {
    height: 50vh;
    position: relative;
    z-index: 0;
    .ellipse-container {
      display: none;
    }
  }
}
