nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .brand-container {
    .brand {
      img {
        width: 80px;
        height: auto;
      }
    }
    .toggle-container {
      display: none;
    }
  }
  .links-container {
    .links {
      list-style-type: none;
      display: flex;
      align-items: center;
      gap: 4rem;
      li {
        .dark {
          color: black;
        }
        .light {
          color: yellow;
        }
        a {
          color: var(--accent-color3);
          text-decoration: none;
          font-size: 20px;
        }
        &:last-of-type {
          a {
            color: var(--pink);
          }
        }
        button {
          color: var(--accent-color1);
          background-color: var(--background);
          font-weight: bold;
          padding: 1rem 3rem;
          border-radius: 2rem;
          box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
          border: solid 3px transparent;
          background-image: linear-gradient(
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0)
            ),
            linear-gradient(101deg, var(--pink), var(--green));
          background-origin: border-box;
          background-clip: content-box, border-box;
          box-shadow: 2px 1000px 1px var(--background) inset;
          transition: 0.5s ease-in-out;
          cursor: pointer;
          &:hover {
            box-shadow: none;
          }
        }
        .mynfts {
          margin-right: 2rem;
        }
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  nav {
    position: relative;
    padding: 1rem 2rem;
    z-index: 99;
    .brand-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .brand {
        img {
          height: 3rem;
          width: auto;
        }
      }
      .toggle-container {
        display: block;
        color: var(--accent-color1);
        display: flex;
        flex-direction: row-reverse;
        gap: 1rem;
        z-index: 40;
        .toggle {
          z-index: 40;
          display: block;
        }
      }
    }
    .links-container {
      z-index: 30;
      background-image: linear-gradient(101deg, var(--pink), var(--orange));
      height: 100vh;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      width: 0;
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s ease-in-out;
      .links {
        flex-direction: column;
        margin: 0;
        padding-left: 0;
        li {
          a {
            color: var(--background);
          }
        }
        li.light {
          display: none;
        }
        li:last-child {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          a {
            color: var(--accent-color3);
            text-decoration: underline;
            margin-bottom: 2rem;
            margin-right: 0;
          }
        }
      }
    }
    .nav-visible {
      width: 100vw;
      visibility: visible;
      opacity: 1;
    }
  }
}
