.modal-dialog {
  max-width: 50%;
  .modal-content {
    background-image: url("../../assets/img/new_images/bingoverse_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    .modal-content-inner {
      position: relative;
      bottom: -250px;
      .modal-title {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      .modal-footer {
        border: none;
        padding: 0;
        padding-right: 1rem;
        padding-left: 1rem;
        button {
          color: var(--accent-color1);
          background-color: var(--background);
          font-weight: bold;
          padding: 1rem 3rem;
          border-radius: 2rem;
          box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
          border: solid 3px transparent;
          background-image: linear-gradient(
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0)
            ),
            linear-gradient(101deg, var(--pink), var(--green));
          background-origin: border-box;
          background-clip: content-box, border-box;
          box-shadow: 2px 1000px 1px var(--background) inset;
          transition: 0.5s ease-in-out;
          cursor: pointer;
          &:hover {
            box-shadow: none;
          }
        }
        .coin-animation {
          position: relative;
          width: 40px;
          height: 40px;
          perspective: 1000px;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            animation: flip-coin 1.5s infinite;
            transform-style: preserve-3d;
          }
        }
      }
    }
  }
}

@keyframes flip-coin {
  0% {
    transform: rotateY(0deg) rotateX(0deg);
  }
  50% {
    transform: rotateY(180deg) rotateX(90deg);
  }
  100% {
    transform: rotateY(360deg) rotateX(0deg);
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .modal-dialog {
    max-width: 100%;
    .modal-content {
      background-size: contain;
      height: 600px;
    }
  }
}
