footer {
  margin: 1rem 0;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .upper {
    display: flex;
    justify-content: space-between;
    color: var(--accent-color3);
    .brand-container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      .brand {
        img {
          width: 80px;
          height: auto;
        }
      }
      ul {
        list-style-type: none;
        display: flex;
        gap: 1rem;
        li {
        }
      }
    }
    .links {
      display: flex;
      gap: 1rem;
      .coin-animation {
        position: relative;
        width: 40px;
        height: 40px;
        perspective: 1000px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          animation: flip-coin 1.5s infinite;
          transform-style: preserve-3d;
        }
      }
      a svg {
        color: var(--green);
        font-size: 40px;
      }
    }
  }
  .lower {
    display: flex;
    justify-content: space-between;
    span {
      color: var(--accent-color3);
    }
  }
}

@keyframes flip-coin {
  0% {
    transform: rotateY(0deg) rotateX(0deg);
  }
  50% {
    transform: rotateY(180deg) rotateX(90deg);
  }
  100% {
    transform: rotateY(360deg) rotateX(0deg);
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  footer {
    margin: 0;
    gap: 2rem;
    padding: 1rem 2rem;
    .upper {
      flex-direction: column;
      gap: 2rem;
      .links {
        display: grid;    
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 2rem;
        .link {
          gap: 1rem;
        }
      }
    }
    .lower {
      flex-direction: column;
      gap: 1rem;
      span {
        font-size: 14px;
      }
    }
  }
}
