.blog {
  margin: 5rem 0;
  .title {
    color: var(--accent-color1);
    margin-bottom: 2rem;
  }
  #jsonContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    .icard {
      background-color: var(--accent-color2);
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border-radius: 2rem;
      img {
        height: 250px;
        width: 100%;
        border-radius: 1rem 1rem 0 0;
      }
      .icard-body {
        height: 250px;
        .icard-body-inner {
          height: 210px;
        }
      }
      .icard-title {
        padding: 0.5rem 0;
      }
      .icard-title a {
        color: var(--accent-color1);
        font-size: 24px;
      }
      p,
      h4,
      blockquote strong {
        color: var(--accent-color3);
        letter-spacing: 0.1rem;
        line-height: 1.5rem;
        font-weight: 400;
        font-size: initial;
      }
    }
  }
  .blog-button {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    a {
      color: var(--accent-color1);
      background-color: var(--background);
      font-weight: bold;
      padding: 1rem 3rem;
      border-radius: 2rem;
      text-decoration: none;
      box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
      border: solid 3px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, var(--pink), var(--purple));
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px var(--background) inset;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      &:hover {
        box-shadow: none;
      }
    }
  }
  .icard-button {
    text-align: center;
    a {
      color: var(--accent-color1);
      background-color: var(--background);
      font-weight: bold;
      padding: 1rem 3rem;
      border-radius: 2rem;
      text-decoration: none;
      box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
      border: solid 3px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, var(--pink), var(--green));
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px var(--background) inset;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      &:hover {
        box-shadow: none;
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .blog {
    margin: 0;
    .container {
      #jsonContent {
        grid-template-columns: repeat(1, 1fr);
      }
      .icard {
        padding: 1rem 2rem;
        border-radius: 0;
        &:nth-of-type(2) {
          background-color: var(--background);
        }
      }
    }
  }
}
