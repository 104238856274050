.bingo-bot {
    .bingobot {
        .content {
            margin-top: 0; 
            background-image: url(../../assets/img/new_images/bingobot.png);
            background-repeat: no-repeat;
            background-size: cover;
            .description {
                color: white
            }
        }
        .image-container {
            widgetbot {
                height: 90vh;
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 280px) and (max-width: 1080px){
    .bingo-bot {
        .bingobot .container {
            grid-template-columns: 1fr;
            .image-container {
                display: none;
            }
        }
    }
}