.nftbingoverse {
  .bingobot {
    .image-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .ellipse-container {
        .pink {
          background-color: var(--pink);
        }
      }
    }
    .content {
        margin-top: 0;
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 1080px){
  .nftbingoverse {
      .bingobot .container {
          grid-template-columns: 1fr;
          .image-container {
              display: none;
          }
      }
  }
}
