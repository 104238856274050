.collection {
  position: relative;
  margin: 5rem 0;
  .container {
    background-color: var(--background-orange);
    border-radius: 1rem;
    overflow: hidden;
    z-index: 1;
    .title {
      font-size: 2rem;
      color: var(--accent-color1);
      margin-top: 2rem;
      text-align: center;
    }
    .mint {
      .link {
        color: var(--pink);
        margin-bottom: 2rem;
        font-size: 20px;
      }
      .cost {
        color: var(--accent-color1);
        font-size: 20px;
        text-align: center;
      }
      .buttons {
        text-align: center;
        margin-bottom: 2rem;
        p {
          margin: unset;
          font-size: 20px;
        }
        div {
          color: var(--accent-color1);
        }
        .mint-amount{
          display: flex;
          justify-content: center;
          align-items: center;
          .border-button {
            border-radius: 100%;
            padding: 1rem;
            margin: 1rem;
          }
        }
      }
    }
    button {
      color: var(--accent-color1);
      background-color: var(--background);
      font-weight: bold;
      padding: 1rem 3rem;
      border-radius: 2rem;
      box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
      border: solid 3px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, var(--pink), var(--green));
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px var(--background) inset;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      &:hover {
        box-shadow: none;
      }
    }
    .background {
      z-index: -1;
      .ellipse {
        position: absolute;
        height: 100%;
        width: 28rem;
        border-radius: 100%;
        filter: blur(100px);
      }
      .red {
        top: -10%;
        left: -15%;
        background-color: var(--red);
      }
      .yellow {
        top: -10%;
        right: -15%;
        background-color: var(--yellow);
      }
    }
    .content {
      z-index: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1.5rem;
      height: 100%;
      .slider {
        margin: auto;
        position: relative;
        width: 100%;
        display: grid;
        place-items: center;
        overflow: hidden;
        height: 100%;

        .slide-track {
          display: flex;
          width: calc(250px * 18);
          animation: scroll 40s linear infinite;

          .slide {
            display: flex;
            align-items: center;
            padding: 15px;
            perspective: 100px;

            .card {
              width: 100%;
              transition: transform 1s;
            }
            .card:hover {
              transform: translateZ(20px);
              cursor: pointer;
            }
          }
        }
      }
      .title {
        font-size: 2rem;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin: 0;
    .container {
      padding: 1rem 2rem;
      border-radius: 0;
      .background {
      display: none;
      }
      .title {
        position: relative;
        z-index: 999;
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}
